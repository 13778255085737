import { jwtDecode } from "jwt-decode";

const refreshUseCase = (repository) => async () => {
  try {
    const token = localStorage.getItem("auth");
    var decoded = jwtDecode(token);
    const newJwtAuthToken = await repository(decoded.access);
    localStorage.setItem("auth", JSON.stringify(newJwtAuthToken));

    return true
  } catch (error) {
    return false
  }
};

export default refreshUseCase;
