import axiosInstance from "./axios";

import DashboardController from "../dashboard/controller/dashboardController";
import LoginController from "../login/controller/loginController";

import sessionRepository from "../login/data/repository/sessionRepository";
import sessionUseCase from "../login/domain/usecase/sessionUseCase";
import getExtratoRepository from "../login/data/repository/getExtratoRepository";
import getExtratoUseCase from "../login/domain/usecase/getExtratoUseCase";
import refreshUseCase from "../login/domain/usecase/getExtratoUseCase";
import ePrimeiroAcessoRepository from "../login/data/repository/ePrimeiroAcesso";
import ePrimeiroAcessoUsecase from "../login/domain/usecase/ePrimeiroAcesso";
import primeiroAcessoRepository from "../login/data/repository/primeiroAcesso";
import primeiroAcessoUsecase from "../login/domain/usecase/primeiroAcesso";
import alterarSenhaRepository from "../login/data/repository/alterarSenha";
import alterarSenhaUsecase from "../login/domain/usecase/alterarSenha";
// import getCnpjRaizRepository from "../login/data/repository/getCnpjRaizRepository";
// import getCnpjRaizUseCase from "../login/domain/usecase/getCnpjRaizUseCase";

import buscaTelaPadraoRepository from "@/core/data/repository/buscaTelaPadraoRepository";
import buscaTelaPadraoUseCase from "@/core/domain/usecase/buscaTelaPadraoUseCase";

import gravaTelaPadraoRepository from "@/core/data/repository/gravaTelaPadraoRepository";
import gravaTelaPadraoUseCase from "@/core/domain/usecase/gravaTelaPadraoRepository";

const instance = axiosInstance;

const sessionRepositoryImpl = sessionRepository(instance);
const sessionUseCaseImpl = sessionUseCase(sessionRepositoryImpl);

const getExtratoRepositoryImpl = getExtratoRepository(instance);
const getExtratoUseCaseImpl = getExtratoUseCase(getExtratoRepositoryImpl);

const ePrimeiroAcessoRepositoryImpl = ePrimeiroAcessoRepository(instance);
const ePrimeiroAcessoUsecaseImpl = ePrimeiroAcessoUsecase(
  ePrimeiroAcessoRepositoryImpl
);

const primeiroAcessoRepositoryImpl = primeiroAcessoRepository(instance);
const primeiroAcessoUsecaseImpl = primeiroAcessoUsecase(
  primeiroAcessoRepositoryImpl
);

const alterarSenhaRepositoryImpl = alterarSenhaRepository(instance);
const alterarSenhaUsecaseImpl = alterarSenhaUsecase(alterarSenhaRepositoryImpl);

// const getCnpjRaizRepositoryImpl = getCnpjRaizRepository(instance);
// const getCnpjRaizUseCaseImpl = getCnpjRaizUseCase(getCnpjRaizRepositoryImpl);

const refreshUseCaseImpl = refreshUseCase(sessionRepositoryImpl);

const buscaTelaPadraoRepositoryImpl = buscaTelaPadraoRepository(instance);
const buscaTelaPadraoUseCaseImpl = buscaTelaPadraoUseCase(
  buscaTelaPadraoRepositoryImpl
);

const gravaTelaPadraoRepositoryImpl = gravaTelaPadraoRepository(instance);
const gravaTelaPadraoUseCaseImpl = gravaTelaPadraoUseCase(
  gravaTelaPadraoRepositoryImpl
);

const loginController = (context) =>
  new LoginController(
    context,
    sessionUseCaseImpl,
    getExtratoUseCaseImpl,
    ePrimeiroAcessoUsecaseImpl,
    primeiroAcessoUsecaseImpl,
    alterarSenhaUsecaseImpl
  );

const dashboardController = (context) =>
  new DashboardController(
    context,
    getExtratoUseCaseImpl,
    refreshUseCaseImpl,
    // getCnpjRaizUseCaseImpl,
    buscaTelaPadraoUseCaseImpl,
    gravaTelaPadraoUseCaseImpl
  );

export { loginController, dashboardController };
