import axios from "axios";
import { clearUserToken, getToken } from "@/core/domain/model/jwtAuthToken";
import { URL } from "@/constants";
import sessionRepository from "../login/data/repository/sessionRepository";
import refreshUseCase from "../login/domain/usecase/refreshUseCase";
import { snackbar } from "cids-cgi/lib/util";

const refreshRepositoryImpl = sessionRepository(axios);
const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);

const retries = {
  maxRetries: 10,
  attempt: 1,
};

const axiosInstance = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    retries.attempt = 1;
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    const status = err.response ? err.response.status : null;

    if (status >= 500 && status <= 599) {
      snackbar.show({ message: err.response.data?.message });
      return Promise.reject();
    }

    if (status === 401) {
      if (retries.attempt < retries.maxRetries) {
        retries.attempt++;

        const refreshed = await refreshUseCaseImpl();

        if (refreshed) {
          const jwtAuthToken = getToken();
          const jwtAuthTokenObject = JSON.parse(jwtAuthToken);
          originalRequest.headers["authorization"] =
            "Bearer " + jwtAuthTokenObject.token;

          return axiosInstance(originalRequest);
        }
      }
      clearUserToken();

      window.location.href = "/login";

      snackbar.show({
        message:
          "Você foi desconectado pois o recurso não está liberado para o seu usuário",
      });
      return Promise.reject();
    }
  }
);

export default axiosInstance;
