class Handler {
  trataErro(error) {
    if (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 500:
            return error.response.data.message;
          default:
            return error.response.data.message;
        }
      }
    }

    return error;
  }
}

export default Handler;
