import { snackbar } from "cids-cgi/lib/util";
import router from "../../../router/index";
import User from "../domain/model/user";

export default class LoginController {
  form = new User();
  visiblePassword = true;
  visibleNewPassword = true;
  loading = false;
  dialog = false;
  email = null;
  showSenha = false;
  validUpper = false;
  validLower = false;
  validNumber = false;
  validLength = false;

  formSenha = {
    senha_antiga: null,
    senha_nova: null,
  };

  constructor(
    context,
    sessionUseCase,
    getExtratoUseCase,
    ePrimeiroAcesso,
    primeiroAcesso,
    alterarSenha
  ) {
    this.context = context;
    this.sessionUseCase = sessionUseCase;
    this.getExtratoUseCase = getExtratoUseCase;
    this.ePrimeiroAcesso = ePrimeiroAcesso;
    this.primeiroAcesso = primeiroAcesso;
    this.alterarSenha = alterarSenha;
  }

  mounted() {
    this.form.cliente = localStorage.getItem("COD");
  }

  validatePassword() {
    this.validUpper = /[A-Z]/.test(this.formSenha.senha_nova);
    this.validLower = /[a-z]/.test(this.formSenha.senha_nova);
    this.validNumber = /[0-9]/.test(this.formSenha.senha_nova);
    this.validLength = this.formSenha.senha_nova.length >= 6;
  }

  async login() {
    localStorage.removeItem("auth");
    localStorage.removeItem("DATA");
    localStorage.removeItem("FOR");
    localStorage.removeItem("EMP");
    if (this.context.$refs.formLogin.validate()) {
      this.loading = true;
      try {
        await this.sessionUseCase(this.form);
        const primeiroAcesso = await this.ePrimeiroAcesso();
        if (primeiroAcesso) {
          this.email = await this.primeiroAcesso();
          if (this.email) {
            this.dialog = true;
            return;
          }

          snackbar.show({
            message:
              "Não foi possível acessar sua conta, entre em contato com a empresa para cadastrar um e-mail válido!",
          });
          return;
        }

        if (!this.showSenha) {
          this.showSenha = true;
          return;
        }

        if (this.showSenha) {
          await this.getExtratoUseCase();
          localStorage.setItem("COD", this.form.cliente);
          router.push("/");
          return;
        }
      } catch (error) {
        snackbar.show({ message: error });
      } finally {
        this.loading = false;
      }
    }
  }

  async alteraSenha() {
    try {
      this.validatePassword();

      if (
        !this.validUpper ||
        !this.validLower ||
        !this.validNumber ||
        !this.validLength
      ) {
        snackbar.show({
          message: "A senha deve atender a todos os critérios de segurança!",
          color: "red",
        });
        return;
      }

      if (this.context.$refs.formLogin.validate()) {
        this.formSenha["documento"] = this.form.usuario;
        await this.alterarSenha(this.formSenha);
        this.dialog = false;
        this.context.$refs.formLogin.resetValidation();
        snackbar.show({
          message: "Senha alterada com sucesso, faça o login novamente!",
          color: "green",
        });
        this.form = new User();
      }
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  cancelarSenha() {
    this.formSenha = {
      senha_antiga: null,
      senha_nova: null,
    };
    this.context.$refs.formLogin.resetValidation();
    this.dialog = false;
  }

  toFacebook() {
    window.open("https://www.facebook.com/CGISoftware", "_blank");
  }
  toInstagram() {
    window.open("https://www.instagram.com/cgisoftware/", "_blank");
  }
  toLinkedin() {
    window.open(
      "https://www.linkedin.com/company/cgisoftware/about/",
      "_blank"
    );
  }
  toTwitter() {
    window.open("https://twitter.com/CgiSoftware", "_blank");
  }
}
