<template>
  <div>
    <v-app-bar
      app
      fixed
      clipped-left
      clipped-right
      class="white--text"
      color="primary"
    >
      <v-img max-width="80" src="/static/logo_cgi_software_branca_grande_atualizada.png" alt="logo"></v-img>
      <v-spacer></v-spacer>
      <v-flex class="d-flex align-end flex-column" style="margin-left: 2%">
        <h4 class="font-weight-medium" style="line-height: 1.2">
          {{ usuarioLogado.empresa }}
        </h4>
        <h4 class="font-weight-light" style="line-height: 1.6">
          {{ usuarioLogado.usuario }}
        </h4>
      </v-flex>
      <v-btn class="ml-2" icon @click="logOut">
        <v-icon color="white">mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawerRight: false,
    usuarioLogado: {
      empresa: "",
      usuario: "",
    },
  }),
  mounted() {
    this.usuarioLogado = {
      empresa: localStorage.getItem("EMP"),
      usuario: localStorage.getItem("FOR"),
    };
  },
  methods: {
    logOut() {
      localStorage.removeItem("auth");
      localStorage.removeItem("DATA");
      localStorage.removeItem("FOR");
      localStorage.removeItem("EMP");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
#titulo {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 300;
  font-size: 25px;
}
</style>
