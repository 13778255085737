<template>
  <div>
    <div class="container-fluid mt-8 mx-8">
      <v-row>
        <!-- <v-col cols="6">
          <v-autocomplete
            v-if="controller.cnpjSelecionado > 11 || controller.cnpj.length > 1"
            label="Selecione o CNPJ desejado"
            no-data-text="Sem dados"
            item-text="cnpj"
            outlined
            hide-details
            return-object
            dense
            @change="controller.buscaDadosCnpjSelecionado()"
            :items="controller.cnpj"
            v-model="controller.cnpjSelecionado"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.cnpj"></v-list-item-title>
                <v-list-item-subtitle v-text="item.nome"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col> -->

        <v-spacer></v-spacer>

        <v-col cols="6" class="d-flex align-center justify-end">
          <v-switch
            label="Atualização Automática"
            class="mt-0 pt-0"
            id="checkAutoReload"
            @change="controller.saveCheckedAutoReload()"
            inset
            dense
            hide-details
            color="green"
            v-model="controller.autoReload"
          ></v-switch>
        </v-col>
      </v-row>
    </div>

    <div v-if="!controller.loadingApplication" class="mt-8 mb-8 mx-8">
      <v-row>
        <v-col cols="12" md="6" class="pt-6">
          <v-card>
            <v-card
              color="#e91e63"
              class="mx-4"
              style="position: relative; top: -20px"
              elevation="10"
            >
              <v-card-text>
                <div class="mx-2">
                  <div
                    id="total_balance_chart"
                    style="min-height: 35vh; width: 100%"
                  ></div>
                </div>
              </v-card-text>
            </v-card>

            <v-card-text class="ml-2">
              <h2 class="font-weight-light">Saldo Total:</h2>
              <h1 class="pt-2">
                {{ controller.saldoTotal }}
              </h1>
              <h4 class="py-2 font-weight-light" style="min-height: 60px">
                (Receitas Liberadas + Receitas Bloqueadas) - ( Despesas
                Adiantamento + Despesas Vencidas + Despesas a Vencer )
              </h4>
              <v-divider></v-divider>
              <div class="pt-1 d-flex align-center">
                <v-icon small>mdi-clock-time-eight-outline</v-icon>
                <h4 class="pl-1 pt-1 font-weight-regular">
                  Atualizado em
                  {{ dayjs().format("DD/MM/YYYY - HH:mm:ss") }}
                </h4>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" class="pt-6">
          <v-card>
            <v-card
              color="#4CAF50"
              class="mx-4"
              style="position: relative; top: -20px"
              elevation="10"
            >
              <v-card-text>
                <div class="mx-2">
                  <div
                    id="balance_available_chart"
                    style="min-height: 35vh; width: 100%"
                  ></div>
                </div>
              </v-card-text>
            </v-card>

            <v-card-text class="ml-2">
              <h2 class="font-weight-light">Saldo Disponível:</h2>

              <h1 class="pt-2">
                {{ controller.saldoDisponivel }}
              </h1>

              <h4 class="py-2 font-weight-light" style="min-height: 60px">
                Receitas Liberadas - ( Despesas Adiantamento + Despesas Vencidas
                + Despesas a Vencer )
              </h4>

              <v-divider></v-divider>

              <div class="pt-1 d-flex align-center">
                <v-icon small>mdi-clock-time-eight-outline</v-icon>
                <h4 class="pl-1 pt-1 font-weight-regular">
                  Atualizado em
                  {{ dayjs().format("DD/MM/YYYY - HH:mm:ss") }}
                </h4>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pt-2">
        <v-col class="pt-4" v-for="(card, i) in controller.cards" :key="i">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              min-width="320"
              @click.stop="controller.selectCard(card)"
            >
              <v-card
                :color="card.color"
                height="60"
                width="60"
                class="ml-4 d-flex justify-center align-center"
                style="position: absolute; top: -10px; z-index: 1"
                elevation="4"
              >
                <v-icon size="40" color="white">{{ card.icon }}</v-icon>
              </v-card>

              <v-card-text>
                <h1 class="pt-6 text-end">
                  {{ controller.formataMoeda(card.valueCard) }}
                </h1>

                <h2 class="py-4 font-weight-light text-end">
                  {{ card.title }}
                </h2>
                <v-divider class="py-2"></v-divider>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-dialog
        v-model="controller.dialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <tabela :controller="controller" />
      </v-dialog>
    </div>

    <div class="cs-loader d-flex justify-center" v-else>
      <div class="cs-loader-inner">
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>
  </div>
</template>

<script>
import tabela from "../components/tabela.vue";
import { dashboardController } from "../../di/di";

export default {
  components: { tabela },
  data: (context) => ({
    controller: dashboardController(context),
  }),
  mounted() {
    this.controller.mounted();

    window.addEventListener("resize", () => {
      this.controller.totalBalanceChart.resize({ height: 380 });
      this.controller.balanceAvailableChart.resize({ height: 380 });
    });
  },
};
</script>

<style>
.cs-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cs-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: calc(100% - 200px);
  color: #39496b;
  padding: 0 100px;
  text-align: center;
}

.cs-loader-inner label {
  font-size: 20px;
  opacity: 0;
  display: inline-block;
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
  }
}

.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}
</style>
