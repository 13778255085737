'use strict'

const SESSION_TOKEN_KEY = 'auth'

export function clearUserToken() {
  localStorage.removeItem(SESSION_TOKEN_KEY)

  return localStorage.getItem(SESSION_TOKEN_KEY) === null
}

export function getToken() {
  return localStorage.getItem(SESSION_TOKEN_KEY)
}

